<!-- 用户内部界面 -->
<template>
  <div class="userIndex">
    <header class="header">
      {{$router.app._route.meta.title}}
    </header>
    <main class="main">
      <transition name='fade'>
        <!-- <ZFBAlert
          v-show="isShowAlertZFBMengCen"
          @fromSonMessage='fromSonMessage'
        ></ZFBAlert> -->
      </transition>
      <router-view></router-view>
    </main>

    <footer class="footer">
      <van-tabbar
        active-color="#f83130"
        v-model="active"
        @change="onChange"
        route
      >
        <van-tabbar-item
          to="/cardView"
          icon="home-o"
        >首页</van-tabbar-item>
        <van-tabbar-item
          to="/home"
          icon="credit-pay"
        >我要卖卡</van-tabbar-item>

        <van-tabbar-item icon="service-o">客服</van-tabbar-item>
        <van-tabbar-item
          to="/sellCardRecord"
          icon="credit-pay"
        >卖卡记录</van-tabbar-item>
        <!-- <van-tabbar-item
          to="/transferRecord"
          icon="refund-o"
        >提现记录</van-tabbar-item> -->
        <van-tabbar-item
          to="/personalCenter"
          icon="user-o"
        >个人中心</van-tabbar-item>

      </van-tabbar>
      <a
        href="https://wpa1.qq.com/gqag0O2y?_type=wpa&qidian=true"
        class="jumpQQ"
        ref="jumpQQ"
      ></a>
    </footer>
  </div>
</template>

<script>
import ZFBAlert from "./indexComponent/ZFBAlert";
export default {
  data() {
    return {
      isShowAlertZFBMengCen: false,
      active: 0,
    };
  },

  components: {
    ZFBAlert,
  },

  mounted() {},

  methods: {
    showAlertZFB() {
      this.isShowAlertZFBMengCen = !this.isShowAlertZFBMengCen;
    },
    onChange(index) {
      if (index == 2) {
        this.$refs.jumpQQ.click();
      }
    },
    fromSonMessage(isShow) {
      this.isShowAlertZFBMengCen = isShow;
    },
  },
};
</script>
<style  lang='scss' >
.userIndex {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .header {
    position: fixed;
    top: 0px;
    font-size: 20px;
    color: #fff;
    width: 100%;
    height: 40px;
    background: #c30000;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 999;

    .loginOut {
      border: none;
      width: 100px;
      height: 20px;
      line-height: 20px;
      background: #f83130;
    }
    .setZFB {
      border: none;
      width: 130px;
      height: 20px;
      border-radius: 3px;
      line-height: 20px;
      color: #f83130;
      background: #fff;
    }
  }
  .main {
    margin-top: 40px;
    margin-bottom: 80px;
  }
  .footer {
    border-top: 1px solid #a7a5a5;
    position: fixed;
    bottom: 0px;
    z-index: 99;
  }
  .jumpQQ {
    display: none;
  }
  .van-tabbar--fixed {
    height: 66px;
  }
  .van-tabbar-item--active {
    font-size: 16px;
  }
  .van-tabbar-item__icon {
    font-size: 24px;
  }
}
</style>