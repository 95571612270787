<!-- 设置支付宝账号 -->
<template>
  <div class="ZFBAlert">
    <div class="alertZFB" @click.stop>
      <p class="table">
        <span></span>
        <span> 卖卡前请填写收款支付宝信息</span>
        <span></span>
        <van-icon name="cross" size="0.5rem" @click="showAlert" />
      </p>
      <van-form @submit="save">

        <van-field v-model="userObj.u_ali_account" name="u_ali_account" label="支付宝账号" placeholder="支付宝账号"
          :error-message="ZFBErrMsg" />

        <van-field v-model="userObj.u_ali_realname" name="u_ali_realname" label="真实姓名" placeholder="真实姓名"
          :error-message="realnameMsg" />
        <p class="tips">支付宝账户姓名必须与您填写的收款姓名一致</p>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>

    <!-- </div> -->
  </div>
</template>

<script>
export default {
  props: ["userInfo"],
  data() {
    return {
      userObj: {
        u_ali_account: "",
        u_ali_realname: "",
      },
      realnameMsg: "",
      ZFBErrMsg: "",
    };
  },

  components: {},
  watch: {
    userInfo: {
      handler: function (val) {
        this.userObj = val;
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() { },

  methods: {
    showAlert() {
      this.$emit("fromSonMessage", false);
    },
    save(val) {
      // 清除支付宝中的空格
      val.u_ali_account = val.u_ali_account.replace(/\s*/g, "");
      if (
        /^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/.test(
          val.u_ali_account
        )
      ) {
        this.ZFBErrMsg = "";
      } else {
        this.ZFBErrMsg = "支付宝账号格式错误";
        return false;
      }
      // 去除真实姓名中的空格
      val.u_ali_realname = val.u_ali_realname.replace(/\s*/g, "");

      if (
        /^[\u4e00-\u9fa5]{1,6}(·[\u4e00-\u9fa5]{1,6}){0,2}$/.test(
          val.u_ali_realname
        )
      ) {
        this.realnameMsg = "";
      } else {
        this.realnameMsg = "支付宝真实姓名必须全中文";
        return false;
      }
      this.$axios
        .post("/api/user/updateInfo", val)
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            this.$toast({
              type: "success",
              message: "支付宝信息设置成功，请下滑继续提交卡",
            });
            this.$emit("fromSonMessage", false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
.ZFBAlert {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .alertZFB {
    width: 300px;
    height: 240px;
    // padding-top: 30px;
    border: 3px solid #fff;
    background: #fff;
    border-radius: 20px;
    z-index: 999;
  }

  .table {
    padding: 0px;
    margin: 10px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ZFBInput {
    margin-top: 20px;
    float: left;
    color: #000;

    p {
      margin: 10px 5px;
      width: 290px;
      text-align: center;
    }

    input {
      border: none;
      background: #f7f7f7;
      height: 50px;
      width: 90%;
      border-radius: 25px;
      padding-left: 5px;
    }
  }

  .tips {
    color: #fd5151;
    text-align: center;
    font-size: 13px;
    width: 290px;
  }

  .save {
    background: #1fbb5e;
    width: 290px;
    height: 50px;
    border: none;
    border-radius: 25px;
    color: #fff;
    font-size: 20px;
    font-weight: 100;
    margin: 10px 5px;
  }
}
</style>
